
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';

	@Component({
		name: 'CountdownInput',
		components: { CaptionText },
		computed: {},
	})
	export default class CountdownInput extends Vue {
		@Prop() readonly date: string;
		@Prop({ default: true }) readonly fullView: boolean;

		now = Math.trunc(new Date().getTime() / 1000);

		get dateToNumber() {
			return Math.trunc(Date.parse(this.date) / 1000);
		}

		get seconds() {
			return (this.dateToNumber - this.now) % 60;
		}

		get minutes() {
			return Math.trunc((this.dateToNumber - this.now) / 60) % 60;
		}

		get hours() {
			return Math.trunc((this.dateToNumber - this.now) / 60 / 60) % 24;
		}

		get days() {
			return Math.trunc((this.dateToNumber - this.now) / 60 / 60 / 24);
		}

		twoDigitsConversion(value) {
			if (value.toString().length <= 1) {
				return '0' + value.toString();
			}
			return value.toString();
		}

		mounted() {
			window.setInterval(() => {
				this.now = Math.trunc(new Date().getTime() / 1000);
			}, 1000);
		}
	}

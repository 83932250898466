
	import { Component, Vue, Prop } from 'vue-property-decorator';

	/// icons list
	import IconEye from '@pixcap/ui-library/components/Icons/IconEye.vue';
	import IconFailed from '@pixcap/ui-library/components/Icons/IconFailed.vue';
	import IconEyeBlock from '@pixcap/ui-library/components/Icons/IconEyeBlock.vue';

	@Component({
		name: 'InputWrapper',
		components: {
			IconEyeBlock,
			IconEye,
			IconFailed,
		},
	})
	export default class InputWrapper extends Vue {
		@Prop() readonly label: string;
		@Prop() readonly errorMsg: string;
		@Prop() readonly value: string;
		@Prop() readonly type: 'text' | 'email' | 'password' | 'number' | 'textarea';
		@Prop() readonly readonly: boolean;
		@Prop() readonly placeholder: string;
		@Prop() readonly size: 'medium' | null;
		@Prop() readonly rounded: 'none' | 'right' | 'left' | null;
		@Prop() readonly errorIndicator: boolean;
		@Prop() readonly list: string;
		@Prop({ default: '3' }) rows: string; // for textarea type

		isPasswordVisible = false;

		get wrapperClassNames() {
			let className = 'input-wrapper';
			if (this.errorMsg) className += ' input-wrapper--error';
			if (this.size) className += ` input-wrapper--${this.size}`;
			return className;
		}

		get inputClassName() {
			let className = 'input-wrapper__modal';
			if (this.isNotTextArea) className += ' input-wrapper__input';
			else className += ' input-wrapper__textarea';
			if (this.rounded) className += ` input-wrapper__input-raduis--${this.rounded}`;
			return className;
		}
		get showIndicatorError() {
			return this.errorMsg && this.errorIndicator;
		}

		get isPasswordInput() {
			return this.type === 'password';
		}

		get inputType() {
			if (this.isPasswordInput && this.isPasswordVisible) return 'text';
			return this.type;
		}

		get isNotTextArea() {
			return this.type !== 'textarea';
		}

		get iconSize() {
			if (this.size === 'medium') return 12;
			return 14;
		}

		handleInput(e) {
			this.$emit('input', e.target.value);
		}
	}
